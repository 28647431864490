@import "variables";
@import "colors";

$font-size: 13px;
$font-family: $font-family-sans-serif;
// $line-height: $line-height-computed;

$base-text: $text-color;

$accent: $brand-primary;
$primary: $brand-primary;
$selected-bg: $brand-primary;

$error: $brand-danger;
$warning: $brand-warning;
$success: $brand-success;
$info: $brand-info;

$base-border-color: #d3d3d3;

$base-border: $base-border-color;
