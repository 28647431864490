@import "variables";
@import "colors";

$msg-border: #dee2e6;

.notification-sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $msg-border;
  background-color: white;
  flex: 1 1 0;
  min-width: 280px;
  max-width: 280px;

  &.popup {
    position: fixed;
    z-index: 1030;
    top: 65px;
    bottom: 0;
    left: 0;
    /*max-width: 100%;*/
    transition: all .15s ease-in-out;
    transform: translateX(-100%);
  }

  &.active {
    transform: translateX(0);
  }

  .sidebar-header {
    border-bottom: 1px solid $msg-border;

    .sidebar-btn {
      display: flex;
      padding: 1.2rem 1rem;
      border-radius: 0;
      align-items: center;
      justify-content: center;

      .sidebar-btn-icon {
        width: 2.5em;
        height: 2.5em;
        margin-right: 1em;
      }

    }
  }

  .k-grid td {

    border-bottom: 1px solid #dee2e6;
    padding: 0;

    a,
    a:hover {
      text-decoration: none;
    }
  }

  .k-state-selected {
    .sidebar-list-item {
      position: relative;
      background-color: #f8f9fa;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: '';
        border-right: 2px solid #ff983e;
      }
    }
  }

  .sidebar-list-item {
    display: flex;
    padding: 1rem 1rem;
    transition: all .15s ease-in-out;
    transition-property: background-color;
    color: #212529;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 0.5rem;

    &:hover {
      background-color: #f8f9fa;
    }

    .sidebar-list-item-heading {
      margin-bottom: .25em;
      line-height: 1.35rem;

      > small {
        color: #6c757d;
        font-size: .765rem;
        vertical-align: top;
      }
      > .material-icons {
        vertical-align: top;
        color: $brand-primary;
        padding-right: 10px;
      }
    }

    .sidebar-list-item-content {
      margin-bottom: 0;
      color: #6c757d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
    }

    .sidebar-list-item-info {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      text-align: right;
      align-items: flex-end;

      .sidebar-list-item-time {
        font-size: .765rem;
        line-height: 1.35rem;
        color: #6c757d;
        white-space: nowrap;
        font-style: italic;
      }

      .sidebar-list-item-counter {
        font-size: .765rem;
        display: inline-flex;
        width: 1.75em;
        height: 1.75em;
        color: white;
        border-radius: 50%;
        background-color: #ff684a;
        align-items: center;
        justify-content: center;
      }

    }

  }

}
