// General styles
// ==============

body {
  overflow-x: hidden;
  background-color: $body-bg;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  min-width: 360px;

  // Prevent .wrapper and .sidebar transition on page load in IE

  /*&:not(.body_loaded) {

        .wrapper, .sidebar {
            transition: none !important;
        }
    }*/
}

a {
  text-decoration: none;
  color: $brand-primary;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  &:hover {
    text-decoration: none;
    color: $brand-primary-600;
  }
}

:focus {
  outline: none;
}

.inline-block {
  display: inline-block;
  vertical-align: top;
}

// Wrapper
// =======

.wrapper {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 165px);
  padding-left: $sidebar-width;
  transition-property: padding;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  @include translateZ(0, 0, 0);

  //@media(max-width: $screen-sm-max) {
  @include media-breakpoint-down(sm) {
    position: static;
    padding-left: 0;
  }

  .sidebar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: $sidebar-width;
    height: 100%;
    z-index: 100;
    transition-property: -webkit-transform, transform;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

    //@media(max-width: $screen-sm-max) {
    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 65px;
      height: 100%;
      /*-webkit-transform: translateX(-$sidebar-width);
      transform: translateX(-$sidebar-width);*/
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  &.hidden {
    padding-left: 0;

    .sidebar {
      -webkit-transform: translateX(-$sidebar-width);
      transform: translateX(-$sidebar-width);

    }

    //@media(max-width: $screen-sm-max) {
    @include media-breakpoint-down(sm) {
      //height: 100%;


      .sidebar {
        -webkit-transform: translateX(-$sidebar-width);
        transform: translateX(-$sidebar-width);
      }
    }
  }
  .container-fluid {
    padding-left: $grid-gutter-width * 0.75;
    padding-right: $grid-gutter-width * 0.75;

    > .row {

      [class*="col-"] {
        padding-left: $grid-gutter-width * 0.25;
        padding-right: $grid-gutter-width * 0.25;

        > .row {
          padding-left: $grid-gutter-width * 0.25;
          padding-right: $grid-gutter-width * 0.25;
        }
      }
    }
  }
}

.flex-row {
  display: flex;
  position: relative;
  align-items: flex-end;

  > .k-widget,
  > .btn {
    /*border:1px red solid;*/
    /*text-align:center;*/

    margin-right: 5px; /* and that, will result in a 10px gap */
  }

}

@media (max-width: 767px) {
  #beacon-container {
    display: none;
  }
}

@media (max-width: 400px) {
  .filter-row {
    flex-wrap: wrap;

    .date-text {
      display: none;
    }
    .date-text-empty {
      display: inline-block;
    }
  }
}

@media (min-width: 400px) {
  .filter-row {
    .date-text {
      display: inline-block;
    }
    .date-text-empty {
      display: none;
    }
  }
}
// Row: justified
// ===========

.row-justified {

  //@media(min-width: $screen-lg-min) {
  @include media-lg() {
    display: flex;
    align-items: stretch;

    > [class*="col-"] {

      > .panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: #{"calc(100% - 15px)"};

        > .panel-heading, > .panel-body, > .list-group, > .panel-footer {
          width: 100%;
        }
        > .panel-footer {
          margin-top: auto;
        }
      }
    }
  }
}
