/**
 * Alerts
 */

$alert-border-radius: 0px;

.alert {
	position: relative;
	padding: 25px;
	border-width: 0;
	border-left: 3px solid $brand-info;
	background-color: white;
	color: $text-color;
	box-shadow: 0 1px 3px fade(black,12%), 0 1px 2px fade(black,24%);

	[data-dismiss] {
		position: absolute;
		top: 10px; right: 10px;
	}
}

.notification-container {
  .alert {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    padding-right: 64px;
    max-width: 100%;
    max-height: 300px;
  }
}

// Contextual classes

@mixin alert($color){
	border-color: $color;
	background-color: tint($color,75%);
	color: shade($color,50%);
}
.alert-success {
	@include alert($brand-success);
}
.alert-info {
	@include alert($brand-info);
}
.alert-warning {
	@include alert($brand-warning);
}
.alert-danger {
	@include alert($brand-danger);
}
