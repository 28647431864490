/**
 * Modals
 */
.k-window {
  background-color: white;
}

.k-window-titlebar + .k-window-content {
  margin-top: -.5em;
  padding-top: 1rem;
}

.modal-content {
	border-radius: $border-radius-base;
}
.modal-header {
	padding: 20px 15px;
	border-bottom: 0;

	.modal-title {
		font-size: 18px;
		font-weight: 400;
	}
}
.modal-body {
	padding-top: 0;
}
.modal-footer {
	border-top: 1px solid $grey-light;
	background-color: $grey-lighter;
  padding: 15px;
  text-align: right;
}


.full-height-dialog {
  .k-dialog {
    width: 100%;
  }

  .k-dialog-content {
    height: 100%;

    .modal-body {
      height: 100%;
    }

  }
}

.referral-dialog {
  .k-dialog-content {
    padding-top: 0;
    margin-top: -16px;
  }
}

.no-padding {
  .k-window-content {
    padding: 1rem 0 0;
  }

  .modal-body {
    padding: 0;
  }
}
