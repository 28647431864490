// Components
// ==========

$padding-base-vertical: 8px;
$padding-base-horizontal: 16px;

$padding-large-vertical: 12px;
$padding-large-horizontal: 20px;

$border-radius-base: 2px;
$border-radius-small: 2px;
$border-radius-large: 2px;