/**
 * Sidebar
 */

$sidebar-width: 240px;

.sidebar {
  background-color: $sidebar-bg;
  box-shadow: 0 0 5px 0 fade(black, 25%);
  width: $sidebar-width;
  -ms-flex: 0 0 $sidebar-width;
  flex: 0 0 $sidebar-width;
  overflow: auto;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.sidebar__close {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.3;
  cursor: pointer;

  > img {
    width: 15px;
    height: 15px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

/*** Sidebar user ***/
.sidebar__user {
  padding: 15px 22px;
  background: url(~assets/kite/img/sidebar__bg.png) no-repeat center center / cover;
}

.sidebar-user__avatar {
  width: 50px;
  height: 50px;
  background-color: $brand-primary;
  color: white;
  font-size: 18px;
  line-height: 45px;
  text-align: center;
  vertical-align: top;

  margin-right: 15px;
  border-radius: 50%;
  border: 3px solid rgba(255, 171, 0, .1);
  overflow: hidden;

  display: inline-block;
}

.sidebar-user__info {
  display: inline-block;
  position: relative;
  padding-right: 5px;

  > h4 {
    margin-bottom: 5px;
    font-size: $font-size-base;
    font-weight: 700;
    color: $text-color;
  }

  > p {
    font-size: $font-size-small;
    color: $text-muted;
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}

.sidebar-user__nav {
  padding-top: 0 !important;
}

/*** Sidebar nav ***/

.sidebar__nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  > li {
    display: block;

    > a {
      position: relative;
      overflow: hidden;
      display: block;
      padding: 15px 15px;
      background-color: transparent;
      border-left: 3px solid transparent;
      color: $text-color;
      font-weight: 700;
      font-size: 0.9125rem;
      transition: all .15s;
      outline: none !important;
      @extend .click-animation;

      > i {
        display: inline-block;
        line-height: $spacer-y;
        font-size: 1.2em;
        vertical-align: middle;
        color: $text-muted;

        &:first-child {
          min-width: 30px;
        }

        &.material-icons {
          font-size: 20px;
        }
      }

      &:hover, &:focus, &.active {
        background-color: $brand-grey-200;
        border-left-color: $brand-accent;
        text-decoration: none;
      }
    }

    &.open > a, &.active > a, &.active > a:hover, &.active > a:focus {
      background-color: $brand-grey-200;
      border-left-color: $brand-accent;
    }
  }
}

.sidebar-nav__heading {
  margin-top: 10px;
  padding: 15px 22px;
  font-size: $font-size-small;
  font-weight: 500;
  text-transform: uppercase;
  color: $text-muted;
}

.sidebar-nav__dropdown {

  > a {
    @include clearfix();

    &:before {
      display: block;
      content: '\f107';
      font-family: 'FontAwesome';
      float: right;
      line-height: $spacer-y;
      transform-origin: 50% 50%;
    }

    &[aria-expanded="true"] {
      background-color: $brand-grey-200;
      border-left-color: $brand-accent;
    }
  }
}

.sidebar-nav__submenu {
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 3px solid $brand-accent;

  &.collapse {
    display: none;

    &.in {
      display: block;
    }
  }

  > li {
    display: block;

    > a {
      display: block;
      padding: 8px 5px 8px 34px;
      color: $text-color;
      font-weight: 400;

      &:hover, &:focus {
        color: $text-color;
        text-decoration: none;
      }

      &:target {
        color: $text-color;
      }

      &.active {
        color: $text-color;
      }

      > i {
        display: inline-block;
        line-height: $spacer-y;
        font-size: 1em;
        vertical-align: middle;

        &:first-child {
          min-width: 35px;
          text-align: center;
        }

        &.material-icons {
          font-size: 1em;
        }
      }

    }

    &.active {
      > a {
        color: $text-color;
        background-color: $brand-grey-200;
      }
    }
  }
}

/*** Sidebar: Custom scrollbar ***/

.ps-scrollbar-y-rail {
  width: 5px !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.ps-scrollbar-y {
  width: 5px !important;
  border-radius: 3px !important;
}
