@use "sass:math";
// =====
// Panels
// =====

$panel-heading-padding: 	20px 15px;
$panel-body-padding: 		0 15px 15px;
$panel-footer-padding: 		15px;
$panel-border-radius: 		2px;
$panel-default-border:  	fade(black,7%);
$panel-default-heading-bg: 	transparent;


// General styles
// ==============

.card {
	margin-bottom: math.div($grid-gutter-width, 2);
	border: 0;
	box-shadow: 0 1px 3px fade(black,12%), 0 1px 2px fade(black,24%);

  &.last {
    margin-bottom: 0;
  }
}
.card-header {
	border-bottom: 0;
}
.card-title {
	font-size: 15px;
  @include clearfix();

	> small {
		font-size: .75em;
		color: $text-muted;
	}

	> a {
		&:hover, &:active, &:focus {
			text-decoration: none;
		}
	}
}
.card-body {
	border-top: 0 !important;

  .card-title {
    padding-left: 0;
    padding-bottom: 0;
  }

  &.grid {
    padding: 0;

    > .card-title {
      padding-left: 1.25em;
      padding-bottom: 1.25em;
    }
  }



	> *:first-child {
		margin-top: 0;
	}

	.row {
		margin-left: math.div(-$grid-gutter-width, 4);
		margin-right: math.div(-$grid-gutter-width, 4);
	}
}
.card-footer {
	border-top: 1px solid $grey-light;
	background-color: $grey-lighter;

	//@media(max-width: $screen-xs-max) {
  @include media-xs() {
	 	text-align: center;
	}

	.row {
		margin-left: math.div(-$grid-gutter-width, 4);
		margin-right: math.div(-$grid-gutter-width, 4);
	}
}
