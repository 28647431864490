/**
 * Custom Styles
 */
//$fa-font-path: "assets/fonts";

//@import "~font-awesome/scss/font-awesome.scss";

// Plugins

/*@import "../plugins/bootstrap-datetimepicker/less/_bootstrap-datetimepicker.less";
@import "../plugins/bootstrap-slider/less/variables";
@import "../plugins/bootstrap-slider/less/rules";
@import "../plugins/bootstrap-colorpicker/less/colorpicker";*/

// Components

@import "components/functions";
@import "components/colors";
@import "components/variables";
@import "components/utilities";
@import "components/media-queries-breakpoints";
@import "components/typography";
@import "components/components";
@import "components/misc";
@import "components/sidebar";
@import "components/buttons";
@import "components/navbar";
@import "components/cards";
@import "components/scaffolding";
@import "components/tables";
@import "components/forms";
@import "components/grid-system";
@import "components/modals";
@import "components/pages";
@import "components/container-sizes";
@import "components/dropdowns";
@import "components/navs";
@import "components/tabs";
@import "components/pills";
@import "components/pagination";
@import "components/form-states-and-alerts";
@import "components/animations";
@import "components/type";
@import "components/code";
@import "components/close";
@import "components/labels";
@import "components/ribbons";
@import "components/tooltips";
@import "components/popovers";
@import "components/alerts";
@import "components/footer";
@import "components/progress-bars";
@import "components/list-group";
@import "components/thumbnails";
@import "components/wells";
@import "components/badges";
@import "components/breadcrumbs";
@import "components/preloader";
@import "components/bootstrap-slider";
@import "components/charts";
@import "components/maps";
@import "components/fullcalendar";
@import "components/timeline";
@import "components/dashboard";
@import "components/messaging";
@import "components/notifications";
@import "components/permissions-component";

@import "components/mobile";
@import "components/print";


/*



@import "components/settings";
*/

// Pages

/*@import "pages/contact";
@import "pages/dashboard";
@import "pages/faq";
@import "pages/inbox";
@import "pages/orders";
@import "pages/profile";
@import "pages/sign";
@import "pages/gallery";
@import "pages/404";*/
