/*---------------print styles---------------*/
@media print {

  .wrapper {
    padding-left: 10px;
  }

  .wrapper .sidebar {
    display: none;
  }

  .print-button,
  .btn-group {
    display: none;
  }

  .container-fluid {
    height: auto;
  }

  .items-container {
    max-height: inherit;
  }

  .col-xs-12 {
    float: none;
  }

  .total-rubic .col-xs-12 {
    float: left;
    width: 25%;
  }

  .panel {
    page-break-inside: avoid;
  }

  .dashboard-stats__item {
    position: relative;
    overflow: hidden;
    padding: 10px 5px;
    /* margin-bottom: 30px; */
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  }
}
