@mixin app-legacy($theme-colors) {

  .k-grid {
    color: map-get($theme-colors, "dark-text");
  }

  .k-grid-header .k-header {
    font-weight: bold;
    color: map-get($theme-colors, "dark-text");
  }

  .table tbody tr {
    th, td {
      color: map-get($theme-colors, "dark-text");
    }
  }

  .card-body .card-title {
    font: 500 16px/24px 'Inter', sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
  }

  .messaging-header,
  .chatbox-item,
  .sidebar-list-item-body {
    h6 {
      font-size: .9rem;
      font-weight: 500;
    }
  }

  .chatbox .chatbox-form-btn {
    background-color: map-get($theme-colors, "primary");
  }

  .upload-wrapper .file-input-label .material-icons {
    color: map-get($theme-colors, "primary") !important;
  }

  .chatbox .form-group .form-control:focus {
    border: 1px solid map-get($theme-colors, "primary");
  }

  .k-dialog-wrapper {
    position: absolute;
  }

  .edit-comp .k-dialog-wrapper {
    position: fixed;
  }

  .k-dialog {
    .k-dialog-titlebar {
      color: map-get($theme-colors, "dark-text");
    }

    h3 {
      color: map-get($theme-colors, "primary");
    }
  }

  .open > .btn-primary, .fc-unthemed .open > .fc-button, .btn-primary, .fc-unthemed .fc-button {
    background-color: map-get($theme-colors, "primary");
    color: white;
  }

  .btn-primary:not([disabled]):not(.disabled):active, .fc-unthemed .fc-button:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .fc-unthemed .fc-button:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .fc-unthemed .show > .dropdown-toggle.fc-button {
    color: #fff;
    background-color: map-get($theme-colors, "primary");
    border-color: map-get($theme-colors, "primary");
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }

  form .form-group label, form .input-group label, .k-grid-edit-row .form-group label, .k-grid-edit-row .input-group label {
    color: map-get($theme-colors, "dark-text");
    font-weight: 500;
  }

  .name-column .label-info {
    background-color: map-get($theme-colors, "primary");
  }

  .btn-link, .btn-link-wrapper button {
    color: map-get($theme-colors, "primary");
  }

  .phone-form-input {
    .form-group {
      max-width: 200px;
    }
  }
}
