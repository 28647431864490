// ========
// Timeline
// ========

.timeline {
    position: relative;
    list-style-type: none;
    padding: 10px 0;
    height: auto;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0; bottom: 0;
        left: 50%;
        margin-left: -1px;
        border-left: 2px solid $grey-light;
        z-index: 0;
    }
    > .year {
        position: relative;
        text-align: center;

        > span {
            display: inline-block;
            padding: $padding-base-vertical $padding-base-horizontal;
            margin: 20px 0;
            border-radius: $border-radius-base;
            background-color: #505050;
            font-weight: 600;
            color: white;
        }
    }
    > .event {
        position: relative;
        width: 50%;
        margin-top: 0;
        margin-bottom: 40px;
        padding-right: 30px;
        padding-left: 0;
        text-align: right;
        float: left;
        clear: left;

        &:nth-child(2n) {
            margin-bottom: 0;
            margin-top: 40px;
            padding-left: 30px;
            padding-right: 0;
            text-align: left;
            float: right;
            clear: right;
        }
        &:before {
            content: '';
            position: absolute;
            top: 33px; right: -8px;
            width: 15px; height: 15px;
            border-radius: 50%;
            background-color: white;
            border: 2px solid $brand-accent;
            transition: background-color .15s;
        }
        &:nth-child(2n):before {
            right: auto;
            left: -7px;
        }
        .event__title {
            position: relative;
            padding: 15px 20px;
            border: 1px solid $grey-light;
            border-bottom: 1px solid $grey-light;
            @include border-top-radius($border-radius-base);
            background-color: $grey-lighter;

            > h3 {
                font-size: 16px;
                font-weight: 400;
                margin: 0 0 10px 0;
            }
            > time {
                display: block;
                color: $text-muted;
            }
            &:before,
            &:after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
            }
            &:before {
                $size: 15px;
                top: 25px;
                right: -$size;
                border-top: $size solid transparent;
                border-left: $size solid $grey-light;
                border-bottom: $size solid transparent;
            }
            &:after {
                $size: 14px;
                top: 26px;
                right: -($size - 1px);
                border-top: $size solid transparent;
                border-left: $size solid $grey-lighter;
                border-bottom: $size solid transparent;
            }
        }
        &:nth-child(2n) .event__title:before {
            $size: 15px;
            right: auto;
            left: -$size;
            border-right: $size solid $grey-light;
            border-left: 0;
        }
        &:nth-child(2n) .event__title:after {
            $size: 14px;
            right: auto;
            left: -($size - 1px);
            border-right: $size solid $grey-lighter;
            border-left: 0;
        }
        .event__content {
            padding: 15px 20px;
            border-width: 0 1px 1px 1px;
            border-style: solid;
            border-color: fade(black, 10%);
            @include border-bottom-radius($border-radius-base);
            > p {
                color: #777777;
                margin-bottom: 0;
                > img {
                    margin-bottom: 10px;
                }
                + .btn {
                    margin-top: 10px;
                }
            }
        }
    }
}

//@media (max-width: $screen-xs-max) {
@include media-xs() {
    .timeline {
        &:before {
            left: 6px;
        }
        > .year {
            margin-left: 6px;
            text-align: left;
            > span {
                border-radius: 0 $border-radius-base $border-radius-base 0;
                margin: 0 0 30px;
            }
        }
        > .event,
        > .event:nth-child(2n) {
            width: 100%;
            padding-left: 30px;
            padding-right: 0;
            margin: 0 0 40px 0;
            text-align: left;
            float: none;
            clear: none;
        }
        > .event:before,
        > .event:nth-child(2n):before {
            top: 20px;
            left: -2px;
            right: auto;
        }
        .event,
        .event:nth-child(2n) {
            .event__title:before {
                $size: 15px;
                right: auto;
                left: -$size;
                border-right: $size solid fade(black, 15%);
                border-left: 0;
            }
            .event__title:after {
                $size: 14px;
                right: auto;
                left: -$size;
                border-right: $size solid #f5f5f5;
                border-left: 0;
            }
        }
    }
}

.timeline_left {
    &:before {
        left: 8px;
    }
    > .year {
        margin-left: 7px;
        text-align: left;
        > span {
            border-radius: 0 $border-radius-base $border-radius-base 0;
            margin: 0 0 30px;
        }
    }
    > .event,
    > .event:nth-child(2n) {
        width: 100%;
        padding-left: 35px;
        padding-right: 0;
        margin: 0 0 40px 0;
        text-align: left;
        float: none;
        clear: none;
    }
    > .event:before,
    > .event:nth-child(2n):before {
        top: 32px;
        left: 0px;
        right: auto;
    }
    .event,
    .event:nth-child(2n) {
        .event__title:before {
            $size: 15px;
            right: auto;
            left: -$size;
            border-right: $size solid $grey-light;
            border-left: 0;
        }
        .event__title:after {
            $size: 14px;
            right: auto;
            left: -($size - 1px);
            border-right: $size solid $grey-lighter;
            border-left: 0;
        }
    }
}

.timeline_right {
    &:before {
        right: 6px;
        left: auto;
    }
    > .year {
        margin-right: 6px;
        margin-left: auto;
        text-align: right;
        > span {
            border-radius: $border-radius-base 0 0 $border-radius-base;
            margin: 0 0 30px;
        }
    }
    > .event,
    > .event:nth-child(2n) {
        width: 100%;
        padding-right: 30px;
        padding-left: 0;
        margin: 0 0 40px 0;
        text-align: right;
        float: none;
        clear: none;
    }
    > .event:before,
    > .event:nth-child(2n):before {
        top: 20px;
        right: -2px;
        left: auto;
    }
    .event,
    .event:nth-child(2n) {
        .event__title:before {
            $size: 15px;
            left: auto;
            right: -$size;
            border-left: $size solid fade(black, 15%);
            border-right: 0;
        }
        .event__title:after {
            $size: 14px;
            left: auto;
            right: -$size;
            border-left: $size solid #f5f5f5;
            border-right: 0;
        }
    }
}
