html {

  --gray1: #000000;
  --gray2: #27282e;
  --gray3: #383a42;
  --gray4: #494b57;
  --gray5: #5a5d6b;
  --gray6: #6c707e;
  --gray7: #818594;
  --gray8: #a8adbd;
  --gray9: #c9ccd6;
  --gray10: #d3d5db;
  --gray11: #dfe1e5;
  --gray12: #ebecf0;
  --gray13: #f7f8fa;
  --gray14: #ffffff;

  --blue1: #2e55a3;
  --blue2: #315fbd;
  --blue3: #3369d6;
  --blue4: #3574f0;
  --blue5: #4682fa;
  --blue6: #588cf3;
  --blue7: #709cf5;
  --blue8: #88adf7;
  --blue9: #a0bdf8;
  --blue10: #c2d6fc;
  --blue11: #d4e2ff;
  --blue12: #edf3ff;
  --blue13: #f5f8fe;
}
