@mixin app-forms($theme-colors) {

  .edit-action-bar {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 15px 30px 5px 30px;
  }

  .has-edit-action-bar {
    margin-bottom: 60px;
  }

  .main-container {
    max-width: 800px;
    margin: 20px;
  }

  .mat-mdc-dialog-content {
    .main-container {
      margin: 0;
    }
  }

  .is-mobile-view {
    .main-container {
      margin: 0;

      .mdc-card {
        border-radius: 0;
        border: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--mdc-outlined-card-outline-color, transparent);
      }
    }
  }

  .mat-mdc-card {
    margin-bottom: 20px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mat-mdc-form-field-infix {
      padding-top: 6px;
      padding-bottom: 6px;
      min-height: 36px;
      line-height: 23px;
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon,
    .mat-mdc-form-field-icon-suffix > .mat-icon {
      padding: 6px;
      box-sizing: content-box;
    }
  }

  .mat-form-field-prefix .mat-mdc-icon-button .mat-icon, .mat-form-field-suffix .mat-icon-button .mat-icon {
    line-height: 1.75;
  }

  .mat-mdc-card-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 16px 16px 0 16px;
  }

  .mdc-card__actions {
    padding: 16px;
  }

  .mat-mdc-form-field-infix {
    width: auto;
  }

  .regular-form-field {
    &.mat-mdc-form-field {
      width: 100%;
      --mat-mdc-form-field-floating-label-scale: 1;
      margin-top: 22px;

      &.no-label {
        margin-top: 0;
      }

      &.inline {
        margin-top: 0;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 12px;
        padding-bottom: 12px;
        min-height: 48px;
        line-height: 24px;
      }

      &.with-icon .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 12px;
        padding-bottom: 0;
      }
    }

    .mat-mdc-form-field-bottom-align::before {
      content: "";
      display: block;
      height: auto;
      margin-top: 3px;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      padding: 0 16px 5px;
    }

    &.mat-mdc-form-field-type-mat-chip-grid {
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .mdc-text-field__input {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: map-get($theme-colors, "dark-text");
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 28px;
      font-weight: 500;
      --mat-mdc-form-field-label-transform: translateY(-50px) scale(var(--mat-mdc-form-field-floating-label-scale, 1));
    }

    .mdc-notched-outline--notched .mdc-notched-outline__notch {
      border-top: 1px solid;
      border-color: rgba(0, 0, 0, 0.38);
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch {
      border-top: 2px solid;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.38);
    }

    .mdc-text-field--disabled .mdc-notched-outline__leading,
    .mdc-text-field--disabled .mdc-notched-outline__notch,
    .mdc-text-field--disabled .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.06);
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
      border-color: var(--mdc-theme-primary, #4862e5);
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: var(--mdc-theme-error, #ff684a);
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
      border-color: var(--mdc-theme-error, #ff684a);
    }

    .mat-mdc-checkbox {
      margin-bottom: 10px;

      &.no-margin {
        margin-bottom: 0;
      }

      label {
        margin: 0;
      }
    }
  }

  .label-text {
    font-size: 14px;
    font-weight: 500;
    color: #1a2451;
  }

  .holder-with-border {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    padding: 10px;
  }

  @media (orientation: landscape) {
    [mode=auto] .mtx-calendar .mtx-calendar-header-time .mtx-calendar-header-hours,
    [mode=auto] .mtx-calendar .mtx-calendar-header-time .mtx-calendar-header-minutes,
    [mode=auto] .mtx-calendar .mtx-calendar-header-time .mtx-calendar-header-ampm {
      width: 45px;
      text-align: center;
    }
  }
}
