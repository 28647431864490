// ==========
// Thumbnails
// ==========

.thumbnail {
	padding: 0;
	border: 0;
	background-color: transparent;

	// Overlay

	&__img {
		position: relative;
		overflow: hidden;

		> img {
			max-width: 100%;
			height: auto;
		}

		&__overlay {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background-color: fade(black,50%);

			&__buttons {
				position: absolute;
				top: 50%; left: 0; right: 0;
				padding: 15px;
				text-align: center;
				transform: translateY(-50%);
			}
		}

		// Hover effects

		&.hover_one {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				opacity: 0;
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					opacity: 1;
				}
			}
		}
		&.hover_two {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				transform: translateY(-100%);
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					transform: translateY(0);
				}
			}
		}
		&.hover_three {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				transform: translateY(100%);
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					transform: translateY(0);
				}
			}
		}
		&.hover_four {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				transform: translateX(100%);
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					transform: translateX(0);
				}
			}
		}
		&.hover_five {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				transform: translateX(-100%);
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					transform: translateX(0);
				}
			}
		}
		&.hover_six {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				transform: scale(.5);
				opacity: 0;
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					transform: scale(1);
					opacity: 1;
				}
			}
		}
		&.hover_seven {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				transform: scale(1.5);
				opacity: 0;
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					transform: scale(1);
					opacity: 1;
				}
			}
		}
		&.hover_eight {

			> img {
				transition: all .5s;
			}
			.thumbnail__img__overlay {
				transform-origin: left top;
				transform: rotate(-45deg);
				opacity: 0;
				transition: all .5s;
			}

			&:hover {

				> img {
					transform: scale(1.1);
				}
				> .thumbnail__img__overlay {
					transform: rotate(0);
					opacity: 1;
				}
			}
		}
	}

	// Caption

	.caption {
		background-color: transparent;

		h3 {
			font-size: 18px;
		}
		p {
			margin-bottom: 0;
			color: $text-muted;

			+ p {
				margin-top: 10px;
			}
		}
	}
}
