// ======
// Tables
// ======

$table-cell-padding: 10px;
$table-bg-accent: $grey-lighter;
$table-bg-hover: $grey-lighter;
$table-border-color: $grey-light;

.table {

  // Header, footer

  thead, tfoot {

    tr {

      th {
        border-bottom-width: 1px;
        vertical-align: middle;
        font-weight: 500;
        font-size: $font-size-small;
        color: $text-muted;

        input {
          font-weight: 400;
        }
      }
    }
  }

  // Body

  tbody {

    tr {

      td {
        vertical-align: middle;
        color: $text-color;
        border-bottom: 1px solid $table-border-color;
        border-top: none;

        strong {
          color: $text-color;
        }
      }
      th {
        font-weight: 500;
        font-size: $font-size-small;
        color: $text-muted;
        border-bottom: 1px solid $table-border-color;
        border-top: none;
      }
    }
  }

}

// Table: Contextual classes
// =========================

.table {

  > thead, > tbody {

    > tr, td, th {

      &.selected, &.selected > td, &.selected > th {
        background-color: $grey-lighter;
      }
      &.success, &.success > td, &.success > th {
        background-color: tint($brand-success, 90%);
      }
      &.danger, &.danger > td, &.danger > th {
        background-color: tint($brand-danger, 90%);
      }
      &.warning, &.warning > td, &.warning > th {
        background-color: tint($brand-warning, 90%);
      }
      &.info, &.info > td, &.info > th {
        background-color: tint($brand-info, 90%);
      }
    }
  }
}

// Table: Avatar
// =============

.table__avatar {
  vertical-align: middle;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid $grey-light;
  overflow: hidden;

  > img {
    width: 100%;
    height: auto;
  }
}

// Table: Status
// =============

.table__status {

  &.online {

    > .fa {
      color: $brand-success;
    }
  }
  &.offline {

    > .fa {
      color: $brand-danger;
    }
  }
  .fa {
    margin-right: 10px;
  }
}

// Table: filters
// ==============

.table__filters {
  margin-bottom: 30px;

  //@media(max-width: $screen-xs-max) {
  @include media-xs() {

    .pull-right {
      float: none;
      width: 100%;
    }
  }
  //@media(min-width: $screen-sm-min) {
  @include media-breakpoint-down(sm) {

    .form-group {
      padding: 0 5px;

      > label {
        margin-right: 10px;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

// Tables: Datatables
// ==================

.dataTables_wrapper {

  .form-control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .dt-buttons {
    margin-bottom: 15px;
  }

}

$grid-bg: white;
$grid-alt-bg: #f9f9f9;
$grid-border-color: $base-border-color;

// Tables: Responsive
// ==================

.table-responsive {
  border-width: 0;
}

// kendo grid
/*grid changes*/

.k-grid-header,
.k-grid-toolbar,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer,
.k-grid-content,
.k-drag-clue {
  background-color: $grid-bg;
}

.k-link {
  cursor: pointer;
  outline: 0;
  text-decoration: none;
}

.k-grid-header {
  .k-header {
    font-weight: bold;
    color: $text-muted;

    > .k-link {
      padding: 10px 12px;
    }

    &.k-first {
      border-left-width: 0;
    }
  }

  .multiline-column {
    white-space: normal;
  }
}

.k-grid-footer {
  td {
    font-weight: bold;
    color: $text-muted;
  }
}

.k-grid {

  /*.k-grid-table {
    transform: none!important;
  }*/

  td, th {
    padding: 6px 12px;
    border-color: $grid-border-color;
    border-width: 0 0 0 0;
  }



  tr {
    &.k-alt {
      background-color: $grid-alt-bg;

      &.k-state-selected {
        color: inherit;
        background-color: rgba(0, 122, 153, 0.25);
      }
    }

    &.unread {
      background-color: rgba(255, 152, 62, 0.25);
    }
  }

  td {
    a,
    a:hover {
      text-decoration: underline;
    }
  }

  .chart-column {
    padding: 0;
  }

  .k-hierarchy-cell {

    .k-icon,
    a, a:hover {
      text-decoration: none;
      color: $text-muted;
    }

    .k-plus:before {
      content: "\e11f";
    }
    .k-minus:before {
      content: "\e122";
    }
  }

  .k-detail-row {

    .k-hierarchy-cell {
      border-width: 1px 0 1px 0;
    }

    .k-detail-cell {
      border-width: 1px 1px 1px 0;

      table td {
        border-width: 0;
      }

    }

    h4 {
      font-size: 15px;

      span {
        vertical-align: middle;
      }
    }
  }
}

.k-pager-wrap {
  color: $text-muted;
  background-color: $grid-bg;
  & .k-link {
    padding: 5px;
    border-color: $grid-border-color;
    border-style: solid;
    border-width: 1px;
  }

  & .k-label {
    margin-right: 4.5em;
  }

  .k-pager-info {
    white-space: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

}

.mobile-row {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;

  span {
    text-align: right;
  }

  > span:first-child {
    flex: auto 1;
    padding-right: 5px;
    text-align: left;
  }
}

.name-column {
  > a.label {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    display: inline-block;
    padding: 0;
    margin-right: 10px;
    border-radius: 50%;
    white-space: nowrap;
    vertical-align: baseline;
    font-size: 75%;
    color: #fff;
  }

  a.label:hover {
    color: #fff;
    cursor: pointer;
  }

  .label-info {
    background-color: $brand-info;
  }
}

/*.k-block, .k-content, .k-dropdown .k-input, .k-popup, .k-toolbar, .k-widget {
  color: #212121;
}*/

/*.k-grid-footer, .k-grid-footer td, .k-grid-header, .k-grid-header .k-header, .k-pager-numbers .k-state-selected, .k-pager-wrap, .k-scheduler-header, .km-pane-wrapper .k-grid-header .k-header {
  color: $text-muted;
  background-color: white;
}

.k-grid-footer:focus {
  color: $text-muted;
  background-color: white;
}

.k-grid-header .k-header, .k-grid-header .k-header .k-link, .k-grid-header .k-link, .k-grid-header .k-link:link, .k-pager-info, .k-scheduler-agendaview .k-scheduler-datecolumn, .k-scheduler-header {
  color: $text-muted;
}*/
