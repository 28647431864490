@mixin app-datatable($theme-colors) {

  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 0.75rem;
    border: none;
    font-weight: 500;
    font-size: 12px;
    color: map-get($theme-colors, "light-text");
    background: map-get($theme-colors, "header-background");
    transition: none;
  }

  .p-datatable .p-datatable-tbody > tr {
    color: map-get($theme-colors, "dark-text");
    font-size: 14px;
  }

  .stretched-container {
    overflow: auto;
  }

  .table-container {
    overflow: auto;

    .regular-table > {

      .mat-mdc-row, .mat-mdc-header-row, .mat-mdc-footer-row  {
        background: #FFFFFF;
        /* Light/03 */

        &.marked-row {
          background-color: #90A2FC;

          .mat-mdc-cell {
            color: #ffffff;
          }

          a.button-32 {
            color: #ffffff;
          }
        }

        font-weight: 500;

        border: 1px solid #E5EAF1;
        box-sizing: border-box;
        /* Row Shadow */

        box-shadow: 0 3px 4px rgba(58, 111, 175, 0.1);
        border-radius: 8px;

        margin-bottom: 6px;
        word-break: break-word;

        .mat-mdc-cell {
          color: map-get($theme-colors, "dark-text");
        }

        &.selected-row {
          font-weight: bold;
        }

        &.mat-expanded-row {
          border-radius: 8px 8px 0 0;
        }

        &.mat-details-row {
          height: auto;
          min-height: auto;
          border: none;
        }

        &.mat-expanded-detail-row {
          //height: auto;
          //min-height: auto;
          border: 1px solid #E5EAF1;
          margin-top: -7px;
          border-radius: 0 0 8px 8px;
        }

        a {
          text-decoration: underline;
        }

        .mat-detail-container {
          overflow: hidden;
        }
      }

      .mat-mdc-footer-row  {
        font-weight: bold;
      }

      .mat-mdc-header-row {
        min-height: 48px;
        background: #F1F5FA;

        .mat-mdc-header-cell {
          color: map-get($theme-colors, "light-text");
        }
      }
    }

    .regular-table {
      &.padding-left-10 {
        mat-cell:first-of-type,
        mat-header-cell:first-of-type,
        mat-footer-cell:first-of-type {
          padding-left: 10px;
        }
      }

      mat-cell:first-of-type,
      mat-header-cell:first-of-type,
      mat-footer-cell:first-of-type {
        padding-left: 24px;
      }

      mat-cell:last-of-type,
      mat-header-cell:last-of-type,
      mat-footer-cell:last-of-type {
        padding-right: 24px;
      }

      .mdc-data-table__cell,
      .mdc-data-table__header-cell {
        padding: 0;
        border-radius: 8px;
        border-width: 0;
        background: transparent;
      }

      .mdc-data-table__header-row {
        height: auto;
        min-height: 48px;
        margin-bottom: 12px;
      }

      .mdc-data-table__row {
        height: auto;
      }
    }

    .regular-table.selectable {
      .mat-mdc-row {
        cursor: pointer;
      }
    }
  }

  .expand-btn {
    &.mat-accent {
      background-color: #E5EAF1;
      color: map-get($theme-colors, "dark-text");
    }
  }

  .mat-mdc-paginator {
    color: #1a24518a;
    background: #FFFFFF;
    /* Light/03 */

    font-weight: 500;

    border: 1px solid #E5EAF1;
    box-sizing: border-box;
    /* Row Shadow */

    box-shadow: 0 3px 4px rgba(58, 111, 175, 0.1);
    border-radius: 8px;

    margin-bottom: 6px;

    .mat-mdc-paginator-container {
      min-height: 48px;
    }

    .mat-mdc-cell {
      color: map-get($theme-colors, "dark-text");
    }
  }

  .spinner-container {
    position: absolute;
    place-content: center;
    align-items: center;
  }

  .mobile-label {
    display: none;
  }

  @media(max-width: 600px) {

    .table-container {
      .responsive {
        &.mat-mdc-table {
          min-width: auto;
        }

        .mobile-label {
          display: inline-block;
          font-weight: bold;
          text-align: left;
        }

        .mat-mdc-cell:first-of-type, .mat-mdc-footer-cell:first-of-type, .mat-mdc-header-cell:first-of-type {
          padding-left: unset;
        }

        mat-cell:first-of-type,
        mat-header-cell:first-of-type,
        mat-footer-cell:first-of-type {
          padding-left: 0;
        }

        .mat-mdc-cell {
          align-items: center;
          min-height: unset;
          flex-direction: row;
          align-self: stretch;
          justify-content: space-between;
          padding: 5px 0;

          min-width: 100%;
          max-width: 100%;
          text-align: right;
        }

        .mat-mdc-header-row {
          display: none;
        }

        .mat-mdc-row {
          flex-direction: column;
          align-items: start;
          padding: 8px 10px;
          min-height: unset; // Tweak for Angular Material 8
        }
      }
    }
  }

  .grid-filter-container {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 9px 5px;
      border-top: 4px solid transparent;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 0;
      padding-bottom: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: 5px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      transform: translateY(0%);
    }
  }

  .filter-chip {
    border-radius: 4px;
    min-height: 29px;
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    &.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
      opacity: 1;
    }
  }
}
