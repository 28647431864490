@mixin app-sms($theme-colors) {

  .selected-number-dropdown {
    .mdc-text-field {
      padding: 0;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: white;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: white;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      padding-bottom: 0;
    }

    .selected-number-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #1a2451de;
    }

    .selected-number {
      display: block;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      padding-left: 0;

      &.warning {
        color: map-get($theme-colors, "warn")
      }
    }
  }

  .messaging-container {
    .compose-btn {
      height: 48px;
      width: 100%;
      padding: 5px 16px;
      text-transform: none;
      text-align: left;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 10px;

      .mat-icon {
        height: 24px;
        width: 24px;
        font-size: 24px;
      }
    }
  }

  .sms-search-field {

    .mat-mdc-text-field-wrapper {
      background-color: #F7F9FD;

      &.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon,
    .mat-mdc-form-field-icon-suffix > .mat-icon {
      padding: 8px;
    }

    .mat-mdc-form-field-infix {
      min-height: 40px;
    }
  }

  .thread-recipients-field {

    .mat-mdc-form-field-infix {
      width: auto;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mdc-notched-outline {
      .mdc-notched-outline__leading {
        border-top: none;
        border-bottom: none;
        border-left: none;
      }

      .mdc-notched-outline__trailing {
        border-right: none;
      }

      .mdc-notched-outline__trailing {
        border-top: none;
        border-bottom: none;
      }
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;

      &.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 40px;
      }

      .mat-mdc-form-field-input-control {
        padding: 10px;
      }
    }

    .mat-mdc-chip-action-label {
      .recipient-title {
        color: #1a2451de;
        font-weight: 600;
      }
    }

    .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
    .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
    .mat-mdc-standard-chip .mat-mdc-chip-action-label {
      overflow: hidden;
    }
    .mdc-evolution-chip__text-label {
      user-select: auto;
    }
  }

  .thread-sort {
    text-align: right;
    margin: 5px;

    .mat-button-toggle-label-content {
      line-height: 1;
      padding: 0 5px;
    }

    .mat-icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
    }
  }

  .messaging-container {
    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;
      width: 325px;
    }

    .mat-mdc-list-item.mdc-list-item--with-one-line {
      height: auto;
    }

    .mdc-list-item--selected {
      background-color: #f7f9fd;
      border-left: 4px solid #39CB76;
    }
  }

  .actions-row {
    .upload-wrapper .file-input-label .material-icons {
      color: map-get($theme-colors, "dark-text") !important;
    }

    .message-text-field {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mat-mdc-text-field-wrapper {
        &.mdc-text-field--outlined .mat-mdc-form-field-infix {
          padding-top: 9px;
          padding-bottom: 9px;
          min-height: 40px;
        }
      }
    }
  }

  .messages-header {
    .mat-mdc-chip.mat-mdc-standard-chip {
      background-color: #F1F5FA;
      min-height: 28px;
      overflow: hidden;
    }
  }

  .message-input {
    textarea.mat-mdc-input-element {
      padding: 0;
      margin: 0;
    }
  }

  .item-container {
    padding: 12px 0;
    border-bottom: 1px solid #EEEFF9;
    min-height: 99px;
    width: 282px;

    .item-avatar {
      font-size: 12px;
      font-weight: 600;

      height: 32px;
      width: 32px;

      .mat-mdc-mini-fab {
        font-size: 12px;
        font-weight: 600;
        box-shadow: none;

        &.cdk-focused,
        &:hover,
        &:active {
          box-shadow: none;
        }
      }

      .pin-conversation-btn {
        margin-top: 10px;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;

        .mat-icon-no-color {
          color: #636E8B;
        }
      }

      .unknown-user {
        span {
          padding-top: 0;
        }

        background-color: #F7F9FD;
        color: #636E8B;
        border: 1px solid #636E8B;

        .mat-icon {
          font-size: 18px;
          height: 18px;
          width: 18px;
        }
      }
    }

    .item-content {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      .item-header {
        .item-heading {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;

          color: #1A2451;
          margin: 0;

          .mat-mdc-icon-button {
            height: 30px;
            width: 30px;
            margin-top: -8px;

            .mat-icon {
              font-size: 18px;
              line-height: 22px;
              height: 18px;
            }
          }

          .item-contact {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            /*max-width: 169px;*/
            display: block;
          }

          .item-phone {
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            padding-top: 5px;
            color: #949CB5;
          }
        }
      }

      .item-last-message {
        min-width: 0;

        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;

          color: #636E8B;
          margin: 0;
        }
      }
    }

    .right-holder {
      margin-left: 5px;

      .item-time {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        color: #636E8B;
      }

      .item-badge {
        padding-right: 12px;
        margin-top: 12px;
      }
    }
  }

  .message-item-right {
    text-align: right;

    .message-item-content {
      background: map-get($theme-colors, "primary-background");
      color: white;
      border-radius: 8px 0 8px 8px;
    }
  }

  .messaging-provider-select {
    width: 42px;
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-arrow-wrapper {
      visibility: hidden;
    }

    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    mat-select-trigger {
      i {
        font-size: 24px
      }
    }
  }

  .messaging-type-option-text {
    i {
      font-size: 24px;
      vertical-align: middle;
      padding-right: 5px;
    }

    span {
      vertical-align: middle;
    }
  }

  .messaging-type-text {
    color: map-get($theme-colors, "primary");
  }

  .messaging-type-waba {
    color: #25D366;
  }

  .message-waba-btn {
    &.active {
      background-color: rgba(#27AE60, 0.2);
      color: #25D366;
    }
  }

  .message-send-btn {
    &.active {
      background-color: rgba(#27AE60, 0.2);
      color: #27AE60;
    }
  }

  .selected-number-popup {
    .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
      right: 0;
    }
  }

  .threads-container {
    .thread-list {
      height: 100%;
      margin-top: -42px;
      padding: 42px 0 0;

      &:focus {
        border: none;
        outline: none
      }
    }

    .thread-viewport {
      height: 100%
    }
  }

  .messages-viewport {
    height: 100%;
    overflow: auto;
  }

  .message-item-footer {
    .status {
      &.status-sent {
        .mat-icon {
          color: #6c757d;
        }
      }

      &.status-delivered {
        .mat-icon {
          color: $brand-success;
        }
      }

      &.status-failed {
        .mat-icon {
          color: $brand-danger;
        }
      }
    }
  }

}
