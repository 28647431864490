/**
 * Miscellaneous
 */

@mixin icon-square($size){
	width: $size;
	height: $size;
	line-height: $size;
	text-align: center;
}
@mixin icon-circle($size){
	@include icon-square($size);
	border-radius: 50%;
}

// Animations
// ==========

// Click animation
.click-animation {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 50%; left: 50%;
		height: 0; width: 100%;
		min-width: 150px; min-height: 150px;
		padding-bottom: 100%;
		border-radius: 50%;
		background-color: fade(black,5%);
		transform: translate(-50%, -50%) scale(1);
		opacity: 0;
		animation: none;
	}

	&:focus:not(:active){

		&:after {
			animation: click-animation .5s linear;
			animation-fill-mode: backwards;
		}
	}
}

@keyframes click-animation {
	0% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0);
	}
	50% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(.5);
	}
	100% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(1);
	}
}

@mixin placeholder($color: $placeholderText) {
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
}

@mixin translateZ($x, $y, $z) {
  -webkit-transform: translateZ($x, $y, $z);
  -moz-transform: translateZ($x, $y, $z);
  -ms-transform: translateZ($x, $y, $z);
  -o-transform: translateZ($x, $y, $z);
  transform: translateZ($x, $y, $z);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate($x, $y, $z);
  -moz-transform: translate($x, $y, $z);
  -ms-transform: translate($x, $y, $z);
  -o-transform: translate($x, $y, $z);
  transform: translate($x, $y, $z);
}

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-right-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-left-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}
