.badge-outgoing {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  .material-icons {
    color: #FFD600;
    font-size: 20px;
    vertical-align: middle;
  }

  span {
    white-space: nowrap;
  }
}

.badge-incoming {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  .material-icons {
    color: #06D48A;
    font-size: 20px;
    vertical-align: middle;
  }

  span {
    white-space: nowrap;
  }
}

.badge-internal {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  .material-icons {
    color: #4862E5;
    font-size: 24px;
    vertical-align: middle;
    transform: rotate(90deg);
  }

  span {
    white-space: nowrap;
  }
}
