$body-bg: #f0f0f0;
$body-color: #212121;

$nav-bar-height: 65px;

$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.8125rem;
$font-size-small: $font-size-base - 0.0625rem;
$font-size-large: $font-size-base + 0.0625rem;

$enable-rounded: true !default;

$spacer: 1rem !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;

$line-height-computed: $spacer-y;

$navbar-padding-x: 1rem !default;
$navbar-padding-y: (0.5rem) !default;

$navbar-padding-vertical: $navbar-padding-y;
$navbar-padding-horizontal: $navbar-padding-x;

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

// Navbar: General
$navbar-height: 65px;
$navbar-margin-bottom: 0;
$navbar-border-radius: 0;
