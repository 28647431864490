
.explanation-title,
a.explanation-title:not([href]):not([tabindex])
{
  cursor: pointer;
  color: $grey;

  .material-icons {
    vertical-align: middle;
    font-size: 19px;
  }

}

.explanation-tooltip {
  white-space: normal;
  background-color: white;
  color: $text-muted;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  padding: 0.25rem 0.5rem;
  max-width: 300px;
  font-weight: 400;
}
