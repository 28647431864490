// ===
// Navs
// ====

$nav-link-padding: 20px 12px;
$nav-link-hover-bg: transparent;


// Tabs
// ====

.nav-tabs {
	background-color: $brand-primary;
	border: 0;
	border-radius: $border-radius-base $border-radius-base 0 0;
	box-shadow: 0 1px 3px fade(black,12%), 0 1px 2px fade(black,24%);

	> li {
		margin: 0;

		> a {
			position: relative;
			margin: 0;
			border-radius: 0;
			border: 0;
			text-transform: uppercase;
			line-height: 1;
			font-size: $font-size-small;
			font-weight: 500;
			color: fade(white,70%);
			overflow: hidden; // required for animation
			@extend .click-animation;

			&:before {
				content: '';
				position: absolute;
				left: 0; right: 0; bottom: 0;
				border-top: 3px solid $brand-accent;
				transform: scale(1,0);
				z-index: 10;
				transition: all .15s;
			}
		}

		&.active {
			margin: 0;

			> a {
				position: relative;
				margin: 0;
				border: 0;
				background-color: transparent;
				color: white;

				&:hover, &:active, &:focus {
					border: 0;
					background-color: transparent;
					color: white;
				}

				&:before {
					transform: scale(1,1);
				}
			}
		}
	}

	&.nav-justified {

		> li {

			> a {
				margin-bottom: 0;
				border: 0;
			}

			&.active {

				> a {
					border: 0;

					&:hover, &:active, &:focus {
						border: 0;
					}
				}
			}
		}
	}
}
.tab-content {

	.panel {
		border-radius: 0 0 $border-radius-base $border-radius-base;

		.panel-heading {
			border-top-right-radius: 0;
			border-top-left-radius: 0;
		}
	}
}
