@mixin app-general($theme-colors) {
  .wrapper {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  a {
    color: map-get($theme-colors, "dark-text");
  }

  .material-icons {
    font-family: 'Material Icons', 'Material Icons';
  }

  .no-underline {
    .mdc-line-ripple {
      &::before {
        border-bottom-width: 0;
      }

      &::after {
        border-bottom-width: 0;
      }
    }
  }

  .menu-no-paddings {
    .mat-mdc-menu-content:not(:empty) {
      padding: 0;
    }
  }

  .content-container {
    padding: 20px;
  }

  .is-mobile-view {
    .content-container {
      padding: 5px;
    }
  }

  .bottom-margin-10 {
    margin-bottom: 10px;
  }

  button {
    &.active-warn {
      background-color: rgba(map-get($theme-colors, "warn"), 0.2);
    }

    &.active-accent {
      background-color: rgba(map-get($theme-colors, "accent"), 0.2);
    }

    &.active-primary {
      background-color: rgba(map-get($theme-colors, "primary"), 0.2);
    }
  }

  .mdc-button {
    min-width: auto;

    .mdc-button__label {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mat-mdc-raised-button.mat-warn {
    --mdc-protected-button-container-color: #ff684a;
    --mdc-protected-button-label-text-color: white;
  }

  .mat-mdc-unelevated-button.mat-warn {
    --mdc-filled-button-container-color: #ff684a;
    --mdc-filled-button-label-text-color: white;
  }

  .button-48 {
    &.mat-mdc-button-base {
      height: 48px;
      width: 48px;
      padding: 12px;
    }

    .mat-mdc-button-touch-target {
      height: 48px;
      width: 48px;
    }

    &.auto-width {
      &.mat-mdc-button-base {
        width: auto;
      }

      .mat-mdc-button-touch-target {
        width: auto;
      }
    }
  }

  .button-40 {
    &.mat-mdc-button-base {
      height: 40px;
      width: 40px;
      padding: 8px;
    }

    .mat-mdc-button-touch-target {
      height: 40px;
      width: 40px;
    }
  }

  .button-32 {
    &.mat-mdc-button-base {
      height: 32px;
      width: 32px;
      padding: 6px;
    }

    .mat-mdc-button-touch-target {
      height: 32px;
      width: 32px;
    }

    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }

  .rect-btn {
    border-radius: 4px;

    .mat-mdc-button-persistent-ripple {
      border-radius: 4px;
    }
  }

  .only-icon {
    padding: 10px;
    min-width: auto;

    .mat-icon {
      margin: 0;
    }
  }

  .no-shadow {
    box-shadow: none;

    &.cdk-focused,
    &:hover,
    &:focus,
    &:active,
    &:focus:active,
    {
      box-shadow: none;
    }
  }

  .no-hint {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .small-button {
    &.mat-mdc-unelevated-button {
      font-size: 12px;
      text-transform: none;
      line-height: 24px;
    }

    .mat-icon {
      background-repeat: no-repeat;
      display: inline-block;
      fill: currentColor;
      height: 18px;
      width: 18px;
      font-size: 18px;
      line-height: 16px;
    }

    &.mat-mdc-icon-button {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }

  .global-messages {
    .p-message {
      margin: 0;
    }
  }

  .mat-mdc-outlined-button > .icon-14 {
    height: 14px;
    width: 14px;
    font-size: 14px;
  }

  .explanation {
    cursor: pointer;
    font-size: 20px;
    height: 20px;
    width: 20px;
    margin-left: 5px;
    vertical-align: text-bottom;
  }
  .mat-tooltip-custom {
    padding: 0.25rem 0.5rem;
    max-width: 300px;
  }

  .mdc-tooltip__surface {
    background-color: white;
    color: map-get($theme-colors, "dark-text");
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    font-size: 13px;
  }

  .height-8 {
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    &.mat-slider-horizontal .mat-slider-wrapper {
      top: 20px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    &.mat-slider-horizontal .mat-slider-track-wrapper {
      height: 8px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    &.mat-slider-horizontal .mat-slider-track-background,
    &.mat-slider-horizontal .mat-slider-track-fill {
      height: 100%;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    &.mat-accent .mat-slider-thumb {
      height: 30px;
      width: 30px;
      background-color: white;
      border: solid 2px gray;
      bottom: -18px;
      right: -23px;
    }

    &.mat-mdc-slider:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb,
    &.mat-slider:not(.mat-slider-disabled).cdk-touch-focused .mat-slider-thumb,
    &.mat-slider:not(.mat-slider-disabled).cdk-program-focused .mat-slider-thumb {
      transform: scale(0.8);
    }
  }

  pdf-viewer {
    .ng2-pdf-viewer-container {
      position: absolute;
      height: auto;
      width: 90%;
    }
  }

  .mat-mdc-dialog-content {
    padding: 1px 24px;
    max-height: initial;
  }

  .regular-label {
    font-size: 14px;
    font-weight: 500;
    color: #1a2451;
  }

  .default-progress-bar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .is-loading {
    .lds-ring {
      display: flex;
      align-items: center;
    }

    &:disabled {
      .lds-ring div {
        border: 2px solid #ccc;
        border-color: #ccc transparent transparent transparent;
      }
    }
  }

  .lds-ring {
    display: none;
    position: relative;
    width: 24px;
    height: 24px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .text-warn {
    color: map-get($theme-colors, "warn");
  }

  .deprecated {
    mat-icon,
    i {
      opacity: 0.5;
    }
  }
  .mat-mdc-icon-button {
    &.deprecated {
      opacity: 0.5;
    }
  }

  .icon-20 {
    width: 20px;
    height: 20px;
    font-size: 21px;
  }

  .icon-40 {
    width: 40px;
    height: 40px;
    font-size: 40px;
  }
}

@mixin app-scroll-bar() {
  :focus {
    outline: none;
  }

  ::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #f8f8f8
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f8f8f8
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #d4d4d4
  }


  textarea::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background-color: #f8f8f8
  }

  ::-webkit-resizer {
    //background-color: #f8f8f8
  }
}
