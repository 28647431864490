@import "variables";
@import "colors";

$msg-border: #dee2e6;

.messaging-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 100px -15px rgba(0, 0, 0, .05);

  .k-grid-header {
    display: none;
  }

  .k-grid tr {
    &.k-alt {
      background-color: inherit;
    }

    &:focus,
    &:hover {
      background-color: inherit;
    }
  }

  .k-grid tr.k-state-selected, .k-grid td.k-state-selected {
    color: inherit;
    background-color: inherit;
  }

  .quickreply-wrapper {
    .modal-body,
    .k-dialog-content {
      padding: 0;
    }
  }

  .template-popup-menu {

    .k-grid tr {
      &.k-alt {
        background-color: inherit;
      }

      &:focus,
      &:hover {
        background-color: #dedede;
      }
    }

    .form-control {
      border: none;

      &:focus {
        border: none;
      }
    }
  }
}

.messaging-main {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #e9ecef;
  align-items: stretch;
}

.messaging-header {
  padding: 1rem;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);

  .header-heading {
    display: flex;
    margin-bottom: 0;
    color: inherit;
    align-items: center;
  }

  .header-heading-img {
    width: 2.5em;
    height: 2.5em;
    margin-right: .5rem;
    border-radius: 50%;
    background-color: $brand-primary;
    color: white;
    text-align: center;
    line-height: 2.5em;
  }

  .btn-outline-primary {
    color: $text-color;
    border: 1px solid $brand-primary;
    background-color: transparent;
    background-image: none;

    &.btn-floating {
      line-height: 38px;
    }

    &:hover,
    &:not(:disabled):not(.disabled):active {
      color: $brand-primary;
      border-color: $brand-primary;
      background-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 122, 153, .5);
    }

  }

  .k-dropdown {
    border: 1px solid $brand-primary;
    color: $text-color;
    background-color: transparent;
    background-image: none;
    border-radius: 2px;

    .k-dropdown-wrap {
      color: $text-color;
      font-weight: 500;
      border: none;

      .k-input,
      .k-select {
        color: $text-color;
        font-weight: 500;

        &:hover {
          color: $brand-primary;
        }
      }

      &:after {
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
        color: $brand-primary;
      }
    }
  }

  .k-dropdown-button {
    border: 1px solid $brand-primary;
    color: $text-color;
    background-color: transparent;
    background-image: none;
    border-radius: 2px;

    button {
      color: $text-color;
      background-color: transparent;
      background-image: none;
      border-radius: 0;
      padding: 0.5rem 0.75rem;

      &:hover {
        color: $brand-primary;
      }
    }

    .k-button {
      &:before {
        background-color: transparent;
      }

    }
  }

  .more-menu-popup {
    .k-dropdown-button {
      border: none;
      color: $text-color;
      background-color: transparent;
      background-image: none;
      border-radius: 0;
      padding: 0;

      button {
        color: $text-color;
        background-color: transparent;
        background-image: none;
        border-radius: 0;
        padding: 0.5rem 0.75rem;
        box-shadow: none;

        &:hover {
          background-color: transparent;
          color: $text-color;
        }
      }
    }
  }

  .header-btn {

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      color: inherit;
    }

    > svg {
      width: 1.25rem;
      height: auto;
    }
  }
}

.messaging-sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $msg-border;
  background-color: white;
  flex: 1 1 0;
  min-width: 280px;
  max-width: 280px;

  &.popup {
    position: fixed;
    z-index: 1030;
    top: 65px;
    bottom: 0;
    left: 0;
    /*max-width: 100%;*/
    transition: all .15s ease-in-out;
    transform: translateX(-100%);
  }

  &.active {
    transform: translateX(0);
  }

  .sidebar-header {
    border-bottom: 1px solid $msg-border;

    .sidebar-btn {
      display: flex;
      padding: 1.2rem 1rem;
      border-radius: 0;
      align-items: center;
      justify-content: center;

      .sidebar-btn-icon {
        width: 2.5em;
        height: 2.5em;
        margin-right: 1em;
      }

    }
  }

  .k-grid td {

    border-bottom: 1px solid #dee2e6;
    padding: 0;

    a,
    a:hover {
      text-decoration: none;
    }
  }

  .k-state-selected {
    .sidebar-list-item {
      position: relative;
      background-color: #f8f9fa;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: '';
        border-right: 2px solid #ff983e;
      }
    }
  }

  .sidebar-list-item {
    display: flex;
    padding: 1rem 1rem;
    transition: all .15s ease-in-out;
    transition-property: background-color;
    color: #212529;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: #f8f9fa;
    }

    .sidebar-list-item-heading {
      margin-bottom: .25em;

      > small {
        color: #6c757d;
      }
    }

    .sidebar-list-item-content {
      margin-bottom: 0;
      color: #6c757d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
    }

    .sidebar-list-item-info {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      text-align: right;
      align-items: flex-end;

      .sidebar-list-item-time {
        font-size: .765rem;
        line-height: 1.35rem;
        color: #6c757d;
      }

      .sidebar-list-item-counter {
        font-size: .765rem;
        display: inline-flex;
        width: 1.75em;
        height: 1.75em;
        color: white;
        border-radius: 50%;
        background-color: #ff684a;
        align-items: center;
        justify-content: center;
      }

    }

  }

}

.chatbox {
  display: flex;
  flex-direction: column;
  flex: 1;

  .form-control {
    border-width: 1px;
    border-radius: 0.25rem;
    background-color: white;
    font-size: .9rem;

    &:focus {
      border: 1px solid $brand-primary;
      box-shadow: 0 0 0 0.2rem rgba(0, 122, 153, .5);
    }
  }

  .form-group {
    .form-control {
      border-width: 1px;
      border-radius: 0.25rem;
      background-color: white;
      font-size: .9rem;

      &:focus {
        border: 1px solid $brand-primary;
        box-shadow: 0 0 0 0.2rem rgba(0, 122, 153, .5);
      }

    }
  }

  .k-multiselect {

    border: 1px solid $brand-primary;
    color: $brand-primary;
    background-color: white;
    background-image: none;
    border-radius: 2px;

    .k-multiselect-wrap {
      border: none;
      padding-left: 5px;
    }

    &.k-state-focused {
      box-shadow: 0 0 0 0.2rem rgba(0, 122, 153, .5);

      .k-multiselect-wrap:after {
        background-color: transparent;
      }
    }
  }

  .chatbox-tel {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: #dee2e6;

    .readonly {
      .k-select {
        display: none;
      }
    }

    form .form-group {
      margin-bottom: 0
    }


  }

  .chatbox-body {
    overflow-y: auto;
    height: 100%;

    position: relative;

    .k-grid {
      background-color: transparent;
      border-width: 0;
    }

    .k-grid-content {
      background-color: transparent;
    }

    .k-grid-pager {
      display: none;
    }

    .progress-wrapper {

      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      display: none;

      .k-i-loading {
        position: absolute;
        top: 0;
        bottom: 0;

        font-size: 100px;
        color: $brand-primary;
        margin: auto;
        background: rgba(0, 122, 153, .05);
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &.is-loading {
        display: block;
      }

    }

    .chatbox-item {
      display: flex;
      margin-right: 1rem;
      margin-bottom: .5rem;
      margin-left: 1rem;
      margin-top: 1rem;

      .chatbox-item-body {
        position: relative;
        width: auto;
        max-width: 75%;
        padding: 1rem 15px;
        border-radius: 2px;
        background-color: white;
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .5);
        min-width: 200px;

        .chatbox-item-heading {
          margin-bottom: .25rem;

          .chatbox-item-info {
            position: absolute;
            top: -20px;
            white-space: nowrap;

            > small {
              color: #6c757d;
            }
          }

          .status {
            position: absolute;
            bottom: 10px;
            right: 10px;
            display: inline-block;
            width: .75em;
            height: .75em;
            margin-left: .5em;
            cursor: default;

            .material-icons {
              font-size: 16px;
            }

            &.status-sent {
              color: #6c757d;
            }

            &.status-delivered {
              color: $brand-success;
            }

            &.status-failed {
              color: $brand-danger;
            }
          }
        }

        .chatbox-item-content {
          margin-bottom: 0;
          margin-right: 15px;
          word-wrap: break-word;

          .image-preview {
            max-width: 100%;
            height: 250px;
          }
        }

        .chatbox-item-footer {
          font-size: .9rem;
          text-align: right;

          > small {
            color: #6c757d;
          }
        }

        &:before,
        &:after {
          position: absolute;
          top: 0;
          content: '';
        }

        &:before {
          top: 1px;
          border-top: .75rem solid rgba(0, 0, 0, .15);
          border-left: 1rem solid transparent;
        }

        &:after {
          border-top: .75rem solid white;
          border-left: 1rem solid transparent;
        }

      }

    }

    .chatbox-item-left {
      justify-content: flex-start;

      .chatbox-item-body {
        background-color: white;

        &:before,
        &:after {
          right: 100%;
        }

        .chatbox-item-info {
          left: 0;
        }

      }

    }

    .chatbox-item-right {
      justify-content: flex-end;

      .chatbox-item-body {
        background-color: #d9ebf0;

        &:after {
          border-top-color: #d9ebf0;
        }

        &:before,
        &:after {
          left: 100%;
          transform: scaleX(-1);
        }

        .chatbox-item-info {
          right: 0;
        }

      }
    }

  }

  .chatbox-footer {
    padding: 1.5rem 15px;
    border-top: 1px solid #dee2e6;
    background-color: #f8f9fa;
    position: relative;

    .file-drop-area {
      display: none;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 24px;
      background-color: #dee2e6;

      div {
        position: absolute;
        top: 30%;
        width: 100%;
        text-align: center;
      }

      &.over {
        display: block;
      }
    }


  }

  .chatbox-form-btn {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: white;
    border: none;
    border-radius: 50%;
    background: none;
    background-color: #007a99;
    box-shadow: none;
    line-height: 1;

    .k-i-loading {
      display: none;
    }

    &.is-loading .material-icons {
      display: none;
    }

    &.is-loading .k-i-loading {
      margin-left: 0;
      font-size: 24px;
      display: inline-block;
      padding: 0 0.10rem;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 122, 153, .5);
    }
  }

}

.highlight-search {
  background-color: #ff983e;
  color: white;
}
