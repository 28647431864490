/**
 * Dropdowns
 */

$dropdown-border: fade(black,5%);

.dropdown-menu {
	border-radius: 0 0 $border-radius-base $border-radius-base;
	box-shadow: 0 2px 4px -1px fade(black,10%), 0 4px 5px 0 fade(black,7%), 0 1px 10px 0 fade(black,6%);
	animation: dropdown-menu .15s;
	transform-origin: top;

	> li {

		> a {
			padding: $padding-base-vertical $padding-base-horizontal;
		}
	}
}

@keyframes dropdown-menu {
	from {
		opacity: 0;
		transform: scale(.5);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

.k-timepicker,
.k-datepicker {
  .k-select {
    background-color: transparent;
    background-image: none;
  }
}

.k-multiselect {
  .k-multiselect-wrap {
    &::after {
      background-color: transparent;
    }

    &::before {
      height: auto;
    }

    .k-button {
      /*margin-top: 0.5rem;
      margin-left: 0.5rem;*/

      padding: 2px 8px;
      margin: 2px 0 0 2px;

    }
  }
}
