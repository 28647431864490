/**
 * Footer
 */

.page__footer {
	padding: 5px;
	color: $text-muted;
  margin-top: -1px;

	a {
		color: $brand-primary;

		&:hover, &:focus {
			color: shade($brand-primary,10%);
		}
	}

	//@media(max-width: $screen-xs-max) {
  @include media-xs() {
		text-align: center;
	}

  &.custom {
    /*position: absolute;
    bottom: 0;
    width: 100%;*/
  }
}

.page__footer__holder {
  width: 100%;
  text-align: center;
}
