
@mixin app-navigation($theme-colors) {

  .main-nav {
    background-color: map-get($theme-colors, "primary-background");
    color: white;
  }

  .secondary-nav {
    background-color: map-get($theme-colors, "secondary-background");
    color: white;
  }

  .main-header {
    .header-icon {
      color: map-get($theme-colors, "header-icon");
    }

    a, button {
      margin-right: 0;
    }

    .user-profile-button {
      margin-right: 0;
      margin-left: 25px;
    }

    &.is-mobile-view .user-profile-button {
      margin-right: 0;
      margin-left: 0;
    }

  }

  .app-launcher {
    max-width: 460px;
  }

  .notifications-menu {
    max-width: 400px;
  }

  .user-profile {
    .mat-mdc-menu-content {
      padding: 0;
    }

    .card {
      margin: 0;
      box-shadow: none;
    }

    .sidebar-user__avatar {
      background-color: white;
      color: initial;
      border: 3px solid map-get($theme-colors, "header-icon");
    }
  }
}
