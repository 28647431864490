.k-tabstrip {
 > .k-tabstrip-items {
   flex-flow: row wrap;

   .k-item.k-state-active,
   .k-item.k-state-selected {
     background-color: white;
   }

 }
  > .k-content {
    border: none;
    background-color: white;
  }
}

.k-tabstrip-top .k-tabstrip-items > .k-item {
  .k-link {
    white-space: nowrap;
    text-align: center;
    display: inline-block;
    width: 150px;
  }
}
