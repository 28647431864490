@import "variables";
@import "colors";

.permissions-tabs {

  .k-content {
    padding: 30px 0 0 0;
  }

}

.permissions-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  margin-bottom: 30px;

  .permission-title-wrapper {
    padding-bottom: 25px;

    strong {
      color: $text-muted;
    }
  }

  .permission-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;

    label {
      padding-right: 25px;
    }

    .extensions-input {
      margin-right: 50px;
    }
  }
}

.permissions-wrapper:last-child {
  border: 0 solid transparent;
}
