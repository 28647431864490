// ==========
// Typography
// ==========

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

// Headings
// =======

.h3, h3 {
  font-size: 1.438rem;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 400;

	> small {
		font-weight: 400;
	}
}

h6, .h6 {
  font-size: .9rem;
  font-weight: 500;
}

// Subheading
// ==========

.subheading {
	margin-bottom: 15px;
	font-size: 16px;
	font-weight: 400;
}


// Page header
// ===========

.page-header {
	font-size: 24px;
	font-weight: 300;
	border-bottom: 0;
  padding-bottom: 8px;
  margin: 20px 0 10px;

	> small {
		font-weight: 300;
		color: $text-muted;
	}
}


// Code
// ====

pre {
	background-color: $grey-lighter;
	border-color: $grey-light;
}


// Blockquotes
// ===========

blockquote {
	border-left: 3px solid $brand-accent;

	&.blockquote-reverse {
		border-right: 3px solid $brand-accent;
	}
}
