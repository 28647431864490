// ====
// Maps
// ====

// Google maps
// ===========

.google-maps__address {
	text-align: right;

	//@media(max-width: $screen-xs-max) {
  @include media-xs() {
	 	margin: 20px 0;
	 	text-align: center;
	}
}
