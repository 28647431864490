@import "functions";
// ==========
// Colors
// ==========

// Brand colors
$brand-red-500:                     #F44336;
$brand-red-400:                     #EF5350;
$brand-red-200:                     #EF9A9A;
$brand-red-700:                     #D32F2F;

$brand-pink-500:                    #E91E63;
$brand-pink-400:                    #EC407A;
$brand-pink-200:                    #F48FB1;
$brand-pink-700:                    #C2185B;

$brand-purple-500:                  #9C27B0;
$brand-purple-400:                  #AB47BC;
$brand-purple-200:                  #CE93D8;
$brand-purple-700:                  #7B1FA2;

$brand-deep-purple-500:             #673AB7;
$brand-deep-purple-400:             #7E57C2;
$brand-deep-purple-200:             #B39DDB;
$brand-deep-purple-700:             #512DA8;

$brand-indigo-500:                  #3F51B5;
$brand-indigo-400:                  #5C6BC0;
$brand-indigo-200:                  #9FA8DA;
$brand-indigo-700:                  #303F9F;

$brand-blue:                        #007a99;
$brand-blue-100: 	                  lighten($brand-blue, 20%);
$brand-blue-200: 	                  lighten($brand-blue, 15%);
$brand-blue-300: 	                  lighten($brand-blue, 10%);
$brand-blue-400: 	                  lighten($brand-blue, 5%);
$brand-blue-500: 	                  lighten($brand-blue, 0%);
$brand-blue-600: 	                  darken($brand-blue, 5%);
$brand-blue-700: 	                  darken($brand-blue, 10%);
/*
$brand-blue-500:                    #2196F3;
$brand-blue-400:                    #42A5F5;
$brand-blue-200:                    #90CAF9;
$brand-blue-700:                    #1976D2;
*/
$brand-light-blue-500:              #03A9F4;
$brand-light-blue-400:              #29B6F6;
$brand-light-blue-200:              #81D4FA;
$brand-light-blue-700:              #0288D1;

$brand-cyan-500:                    #00BCD4;
$brand-cyan-400:                    #26C6DA;
$brand-cyan-200:                    #80DEEA;
$brand-cyan-700:                    #0097A7;

$brand-teal-500:                    #009688;
$brand-teal-400:                    #26A69A;
$brand-teal-200:                    #80CBC4;
$brand-teal-700:                    #00796B;

$brand-green-500:                   #4CAF50;
$brand-green-400:                   #66BB6A;
$brand-green-200:                   #A5D6A7;
$brand-green-700:                   #388E3C;

$brand-light-green-500:             #8BC34A;
$brand-light-green-400:             #9CCC65;
$brand-light-green-200:             #C5E1A5;
$brand-light-green-700:             #689F38;

$brand-lime-500:                    #CDDC39;
$brand-lime-400:                    #D4E157;
$brand-lime-200:                    #E6EE9C;
$brand-lime-700:                    #AFB42B;

$brand-yellow-500:                  #FFEB3B;
$brand-yellow-400:                  #FFEE58;
$brand-yellow-200:                  #FFF59D;
$brand-yellow-700:                  #FBC02D;

$brand-amber-500:                   #FFC107;
$brand-amber-400:                   #FFCA28;
$brand-amber-200:                   #FFE082;
$brand-amber-700:                   #FFA000;

$brand-orange-500:                  #FF9800;
$brand-orange-400:                  #FFA726;
$brand-orange-200:                  #FFCC80;
$brand-orange-700:                  #F57C00;

$brand-deep-orange-500:             #FF5722;
$brand-deep-orange-400:             #FF7043;
$brand-deep-orange-200:             #FFAB91;
$brand-deep-orange-700:             #E64A19;

$brand-brown-500:                   #795548;
$brand-brown-400:                   #8D6E63;
$brand-brown-200:                   #BCAAA4;
$brand-brown-700:                   #5D4037;

$brand-grey-500:                    #9E9E9E;
$brand-grey-400:                    #BDBDBD;
$brand-grey-200:                    #EEEEEE;
$brand-grey-700:                    #616161;

$brand-blue-grey-500:               #607D8B;
$brand-blue-grey-400:               #78909C;
$brand-blue-grey-200:               #B0BEC5;
$brand-blue-grey-700:               #455A64;

$brand-original-500: 				        $brand-blue-500;


// Contextual colors

// New colors

$brand-primary:			    #007a99;
$brand-primary-100: 	  lighten($brand-primary, 20%);
$brand-primary-200: 	  lighten($brand-primary, 15%);
$brand-primary-300: 	  lighten($brand-primary, 10%);
$brand-primary-400: 	  lighten($brand-primary, 5%);
$brand-primary-500: 	  lighten($brand-primary, 0%);
$brand-primary-600: 	  darken($brand-primary, 5%);
$brand-primary-700: 	  darken($brand-primary, 10%);

$brand-primary:         $brand-primary-500;
$brand-primary-light:   $brand-primary-200;
$brand-primary-dark:    $brand-primary-700;

$brand-accent:          #ff983e;
$brand-accent-100: 		  lighten($brand-accent, 20%);
$brand-accent-200: 		  lighten($brand-accent, 15%);
$brand-accent-300: 		  lighten($brand-accent, 10%);
$brand-accent-400: 		  lighten($brand-accent, 5%);
$brand-accent-500: 		  lighten($brand-accent, 0%);
$brand-accent-600: 		  darken($brand-accent, 5%);
$brand-accent-700: 		  darken($brand-accent, 10%);

$brand-danger:          #ff684a;
$brand-danger-100: 		  lighten($brand-danger, 20%);
$brand-danger-200: 		  lighten($brand-danger, 15%);
$brand-danger-300: 		  lighten($brand-danger, 10%);
$brand-danger-400: 		  lighten($brand-danger, 5%);
$brand-danger-500: 		  lighten($brand-danger, 0%);
$brand-danger-600: 		  darken($brand-danger, 5%);
$brand-danger-700: 		  darken($brand-danger, 10%);

$brand-success:        	#05A380;
$brand-success-100: 	  lighten($brand-success, 20%);
$brand-success-200: 	  lighten($brand-success, 15%);
$brand-success-300: 	  lighten($brand-success, 10%);
$brand-success-400: 	  lighten($brand-success, 5%);
$brand-success-500: 	  lighten($brand-success, 0%);
$brand-success-600: 	  darken($brand-success, 5%);
$brand-success-700: 	  darken($brand-success, 10%);

$brand-warning:         #EFB523;
$brand-warning-100: 	  lighten($brand-warning, 20%);
$brand-warning-200: 	  lighten($brand-warning, 15%);
$brand-warning-300: 	  lighten($brand-warning, 10%);
$brand-warning-400: 	  lighten($brand-warning, 5%);
$brand-warning-500: 	  lighten($brand-warning, 0%);
$brand-warning-600: 	  darken($brand-warning, 5%);
$brand-warning-700: 	  darken($brand-warning, 10%);

$brand-info:            $brand-primary;
$brand-info-100: 		    lighten($brand-info, 20%);
$brand-info-200: 		    lighten($brand-info, 15%);
$brand-info-300: 		    lighten($brand-info, 10%);
$brand-info-400: 		    lighten($brand-info, 5%);
$brand-info-500: 		    lighten($brand-info, 0%);
$brand-info-600: 		    darken($brand-info, 5%);
$brand-info-700: 		    darken($brand-info, 10%);

$grey:                  #c6c6cc;
$grey-badge:            #cccccc;
$grey-light:            #ededed;
$grey-lighter:          #f9f9f9;
$grey-dark:             grey;

//$brand-primary:                     $brand-blue-500;
//$brand-primary-light:               $brand-blue-200;
//$brand-primary-dark:                $brand-blue-700;

$component-active-bg:               $brand-primary;

// Typography

$text-color:                        shade(white,87%);
$text-muted:                        shade(white,54);
$text-disabled:                     shade(white,38%);
$text-divider:                      shade(white,12%);

$text-white-color:                  fade-out(white,0);
$text-white-muted:                  fade-out(white,.3);
$text-white-disabled:               fade-out(white,.5);
$text-white-divider:                fade-out(white,.88);

//$text-white-color:                  fade(white,100%);
//$text-white-muted:                  fade(white,70%);
//$text-white-disabled:               fade(white,50%);
//$text-white-divider:                fade(white,12%);


// Navbar colors

$navbar-default-bg:                 $brand-primary;
$navbar-default-border:             transparent;
$navbar-default-color:              $text-white-muted;
$navbar-default-link-color:         $text-white-muted;
$navbar-default-link-hover-color:   $text-white-color;
$navbar-default-link-active-color:  $text-white-color;
$navbar-default-link-active-bg:     transparent;
$navbar-default-brand-color:        $text-white-color;
$navbar-default-brand-hover-color:  $text-white-color;


// Sidebar colors

$sidebar-bg:                        white;

// Links

$link-color: $brand-primary;
$link-hover-color: shade($brand-primary,5%);


// Social icons colors

$facebook-color:        #3b5998;
$twitter-color:         #55acee;
$google-plus-color:     #dd4b39;
$youtube-color:         #bb0000;
$linkedin-color:        #007bb5;
$instagram-color:       #125688;
$whatsapp-color:        #4dc247;
$pinterest-color:       #cb2027;
$vine-color:            #00bf8f;
$dropbox-color:         #007ee5;
$flickr-color:          #ff0084;
$vk-color:              #45668e;
$vimeo-color:           #aad450;
$foursquare-color:      #0072b1;
$spotify-color:         #00e461;
$behance-color:         #1769ff;
$rss-color:             #ff6600;
$github-color:          #181818;
