@import "functions";
// =======
// Buttons
// =======

$btn-border-radius-small: 2px;
$btn-border-radius-base: 2px;
$btn-border-radius-large: 2px;

.btn, .k-button, .k-button button,  {
  background-image: none;
	border: 0;
	outline: none;
	line-height: 20px;
	font-size: $font-size-small;
	font-weight: 500;
	text-transform: uppercase;
	box-shadow: none;
	transition: all 0.15s;
	overflow: hidden; // required for animation
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  color: $text-color;
  white-space: nowrap;

	@extend .click-animation;

  + .btn {
    margin-left: 0.5rem;
  }

	&:hover {
		outline: none;
		box-shadow: 0 1px 3px fade(black,12%);
	}
	&:focus, &:active, &:active:focus {
		outline: none;
		box-shadow: 0 2px 6px fade(black,12%);
	}

  &.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  }

	> .fa {
		display: inline-block;
		min-width: 20px;
	}

  .k-i-loading {
    display: none;
  }

  .material-icons {
    font-size: 21px;
    vertical-align: middle;
  }

  input[type="radio"], input[type="checkbox"] {
    display: none;
  }

}
@mixin btn($bg, $color){
	background-color: $bg;
	color: $color;


	&:hover,
  &.dropdown-toggle:hover,
  &button:hover
  {
    background-image: none;
		background-color: shade($bg,5%);
		color: $color;
	}
	&.dropdown-toggle:focus,
	&:focus, &:active, &:active:focus {
		background-color: shade($bg,10%);
		color: $color;
	}
}

.k-button {
  line-height: 1;
}

// Split buttons dropdowns

.open > .dropdown-toggle.btn:focus {
	border-color: fade(white,50%);
}


// Button: sizes
// =============

.btn-lg {
	font-size: $font-size-base;

  .material-icons {
    font-size: $font-size-base/* - 0.125rem*/;
    line-height: $font-size-base/* - 0.125rem*/;
  }
}
.btn-sm {
  font-size: $font-size-base/* - 0.125rem*/;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  .material-icons {
    font-size: $font-size-base/* - 0.125rem*/;
    line-height: $font-size-base/* - 0.125rem*/;
    padding: 0 0.10rem;
  }

  &.is-loading .k-i-loading {
    margin-left: 0;
    font-size: $font-size-base;
    display: inline-block;
    padding: 0 0.10rem;
  }
}
.btn-xs {
	font-size: $font-size-base - 0.1rem;
  line-height: $font-size-base - 0.1rem;
  padding: 0.25rem 0.25rem;

  .material-icons {
    font-size: $font-size-base - 0.1rem;
    line-height: $font-size-base - 0.1rem;
    padding: 0 0.10rem;
  }

  &.is-loading .k-i-loading {
    margin-left: 0;
    font-size: $font-size-base;
    display: inline-block;
    padding: 0 0.10rem;
  }
}

.btn-default-wrapper.k-widget {
  color: $text-color;
  background-color: transparent;
  border-color: transparent;
  vertical-align: middle;
}

// Button: contextual styles
// =========================

.k-button:not(.k-button-icon) {
  min-width: inherit;
}

.btn-link,
.btn-link-wrapper button {
	@include btn(transparent, $brand-primary);
	border-radius: $border-radius-base;
	box-shadow: none !important;

	&:hover {
		text-decoration: none;
	}
	&:focus, &:active, &:active:focus {
		text-decoration: none;
	}
}

.btn-link-wrapper {
  &.k-widget {
    background-color: transparent;
    border-color: transparent;
  }
}

.open > .btn-default, .btn-default, .btn-default-wrapper button {
	@include btn($grey-light,$text-color);
}
.open > .btn-primary, .btn-primary {
	@include btn($brand-primary, white);
}
.open > .btn-accent, .btn-accent {
	@include btn($brand-accent, white);
}
.open > .btn-danger, .btn-danger {
	@include btn($brand-danger, white);
}
.open > .btn-success, .btn-success {
	@include btn($brand-success, white);
}
.open > .btn-warning, .btn-warning {
	@include btn($brand-warning, white);
}
.open > .btn-info, .btn-info {
	@include btn($brand-info, white);
}


// Button group
// ============

.btn-group {

	.btn {

		+ .btn {
			border-left: 1px solid fade(white,30%);
			margin: 0;
		}
	}

	> .btn-group {

		.btn {

			&:first-child {
				border-left: 1px solid fade(white,30%);
				margin-left: 0;
			}
		}
	}
}
.btn-group-vertical {

	.btn, .btn-group {
		margin: 0;

		+ .btn, + .btn-group {
			margin-top: 1px;
		}
	}
}

.is-loading {
  .k-i-loading {
    margin-left: 8px;
    font-size: 17px;
    display: inline-block;
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $brand-primary;
  border-color: $brand-primary;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: $brand-primary;
  border-color: $brand-primary;
}

// Floating buttons
// ================

.global-loading-indicator {
  font-size: 40px;
  color: white;
  margin-right: 10px;
}

.btn-floating {
	@include icon-circle(56px);
	padding: 0;
	font-size: 16px;
	@extend .click-animation;
	box-shadow: 0 2px 2px 0 fade(black,14%),
				0 1px 5px 0 fade(black,12%),
				0 3px 1px -2px fade(black,20%);

	&:after {
		min-width: 0; min-height: 0;
	}

	&:hover, &.dropdown-toggle:hover, &.dropdown-toggle:focus,
	&:focus, &:active, &:active:focus {
		box-shadow: 0 3px 3px 0 fade(black,14%),
					0 1px 7px 0 fade(black,12%),
					0 3px 1px -1px fade(black,20%);
	}

  .material-icons {
    line-height: 42px;
    font-size: 19px;
  }
}

.round-filter {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 8;
}

.round-filter-next {
  position: absolute;
  top: -5px;
  right: 50px;
  /*z-index: 8;*/
}

.round-export {
  position: absolute;
  top: -5px;
  right: 0px;
  /*z-index: 8;*/
}

.k-list .k-item, .k-list-optionlabel {
  padding: 8px 17px;
}

.k-list .k-item:focus, .k-list .k-item.k-state-focused {
  box-shadow: none;
  /*color: #fff;
  background-color: $brand-primary;*/
  color: #656565;
  background-color: #ededed;
}

.k-dropdown-wrap .k-dropdown .k-state-default {
  box-shadow: none;
  border-color: #d3d3d3;
  background-color: transparent;
}

.k-dropdown-wrap.k-state-hover, .k-dropdown-wrap.k-state-focused, .k-dropdown-wrap.k-state-active {
  box-shadow: none;
  border-color: $brand-accent;
  background-color: transparent;
}

.k-widget .k-input, .k-block .k-input {
  padding: 0.5rem 0.75rem;
  height: 2rem;
  font-size: 0.8125rem;
}

.k-dateinput, .k-datepicker, .k-datetimepicker, .k-timepicker {
  font-size: 0.8125rem;

  .k-input {
    padding: 0.5rem 0.75rem;
    height: 2rem;
  }
}

.list-group-item {
  font-weight: normal;
  cursor: pointer;

  .k-button {
    padding: 0;
    background-color: transparent;
    text-transform: none;
    font-weight: normal;
    font-size: 0.8125rem;
    line-height: 1.42857143;
    color: inherit;
  }
}

.k-i-calendar {

  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 19px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'liga';

  &:before {
    content: "today";
  }
}

.btn-icon {
  text-decoration: none;
  color: $grey;
  padding-right: 5px;
  padding-left: 3px;

  .material-icons {
    font-size: inherit;
  }
}

.k-grid td {
  .btn-icon {
    color: $grey;
    text-decoration: none;
  }
}

.btn-message-more-wrapper {

}
