// ============
// Fullcalendar
// ============

// Overwrite default calendar styling

.fc-unthemed {

	// Toolbar

	.fc-toolbar {

		h2 {
			line-height: 36px;
			font-size: 16px;
		}

		//@media(max-width: $screen-xs-max) {
    @include media-xs(){

		 	.fc-right {
		 		float: left;
		 	}
		 	.fc-left, .fc-right, .fc-center {

		 		+ .fc-left, + .fc-right, + .fc-center {
		 			margin-top: 5px;
		 		}
		 	}
		}
	}

	// Buttons

	.fc-button {
		@extend .btn;
		@extend .btn-primary;
		height: auto;
		text-shadow: none;

		+ .fc-button {
			margin-left: 2px;
		}
	}

	// Day number

	.fc-day-number {
		color: $text-muted;

		&:hover, &:active, &:focus {
			color: $text-color;
			text-decoration: none;
		}
	}

	// Event

	.fc-event {
		background-color: $brand-accent;
		border-color: $brand-accent;
	}

	// Today

	td.fc-today {
		background-color: $grey-lighter;
		border-color: #ddd;
	}

	// List view
	// =========

	.fc-list-view {

		.fc-list-heading td {
			background-color: $grey-lighter;
		}
		.fc-event-dot {
			background-color: $brand-accent;
		}
	}
}
