@mixin app-reports($theme-colors) {
  .call-logs-filter-container {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 9px 5px;
      border-top: 4px solid transparent;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 0;
      padding-bottom: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: 5px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      transform: translateY(0%);
    }
  }

  .filter-chip {
    border-radius: 4px;
    min-height: 29px;
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    &.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
      opacity: 1;
    }
  }
}
