// ======
// Charts
// ======

.chart__legend {
	list-style: none;
	margin-top: 20px;
	padding-left: 50px;

	> li {
		position: relative;

		+ li {
			margin-top: 10px;
		}

		> i {
			position: absolute;
			left: -30px;
			font-size: 1.5em;
			vertical-align: middle;
		}
	}
}

.k-chart {

  .k-handle,
  .k-handle:hover,
  .k-handle.k-handle-active {
    background-color: $brand-accent;
    border-color: $brand-accent;
    background-image: none;
    width: 12px;
    height: 12px;
  }

  .k-right-handle {
    right: -7px;
  }

  .k-left-handle {
    left: -7px;
  }

}
