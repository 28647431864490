// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
@import '@angular/cdk/overlay-prebuilt.css';
@import "src/scss/material/components/general";
@import "src/scss/material/components/forms";
@import "src/scss/material/components/legacy";
@import "src/scss/material/components/navigation";
@import "src/scss/material/components/sms-component";
@import "src/scss/material/components/sms-template-component";
@import "src/scss/material/components/datatable";
@import "src/scss/material/components/reports";
@import "src/scss/material/components/flow";


// Plus imports for other components in your app.
$fontConfig: mat.define-typography-config(
  $font-family: "'Inter', sans-serif",
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(32px, 36px, 700),
  $headline-5: mat.define-typography-level(24px, 32px, 700), /* h1 */
  $headline-6: mat.define-typography-level(24px, 32px, 400), /* h2 */
  $subtitle-1: mat.define-typography-level(16px, 24px, 500), /* h3 */
  $body-1: mat.define-typography-level(14px, 21px, 400), /* h4 */
  $subtitle-2: mat.define-typography-level(14px, 21px, 600),
  $body-2: mat.define-typography-level(14px, 21px, 500),
  $caption: mat.define-typography-level(12px, 12px, 400),
  $button: mat.define-typography-level(14px, 21px, 600),
    // Line-height must be unit-less fraction of the font-size.
  /* $input TODO(mdc-migration): No longer supported. Use `body-1` instead. put: mat.define-typography-level(inherit, 1.25, 400) */
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-component-typographies($fontConfig);
@include mat.core();

//general colors
$light-01: #636E8B;
$light-04: #F1F5FA;

// Light Theme Text
$dark-text: #1a2451;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

mat.$light-theme-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Background config
// Light bg
$light-header-icon: #636E8B;
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

mat.$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-background,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

$mat-primary: (
  main: #4862E5,
  lighter: #c8d0f7,
  darker: #3046da,
  200: #007a99, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$customer-portal-ui-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #4862E5;
  --accent-lighter-color: #c8d0f7;
  --accent-darker-color: #3046da;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: #4862E5,
  lighter: #c8d0f7,
  darker: #3046da,
  200: #39CB76, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$customer-portal-ui-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff684a;
  --warn-lighter-color: #ffd2c9;
  --warn-darker-color: #ff4b32;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff684a,
  lighter: #ffd2c9,
  darker: #ff4b32,
  200: #ff684a, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $light-primary-text,
  )
);
$customer-portal-ui-warn: mat.define-palette($mat-warn, main, lighter, darker);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$customer-portal-ui-theme: mat.define-light-theme((
  color: (
    primary: $customer-portal-ui-primary,
    accent: $customer-portal-ui-accent,
    warn: $customer-portal-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($customer-portal-ui-theme);


.main-theme {

  $navigation-theme: (
    'primary-background': map-get($mat-primary, "main"),
    'secondary-background': #465CCD,
    'header-icon': $light-header-icon
  );

  $sms-theme: (
    'primary-background': map-get($mat-primary, "main"),
    'dark-text': $dark-text,
    'primary': map-get($mat-primary, "main"),
    'accent': map-get($mat-accent, "main"),
    'warn': map-get($mat-warn, "main"),
  );

  $general-theme: (
    'primary': map-get($mat-primary, "main"),
    'accent': map-get($mat-accent, "main"),
    'warn': map-get($mat-warn, "main"),
    'dark-text': $dark-text
  );

  $forms-theme: (
    'primary': map-get($mat-primary, "main"),
    'accent': map-get($mat-accent, "main"),
    'warn': map-get($mat-warn, "main"),
    'dark-text': $dark-text
  );

  $legacy-theme: (
    'primary': map-get($mat-primary, "main"),
    'accent': map-get($mat-accent, "main"),
    'warn': map-get($mat-warn, "main"),
    'dark-text': $dark-text
  );

  @include mat.all-component-themes($customer-portal-ui-theme);
  @include mtx.all-component-themes($customer-portal-ui-theme);

  @include app-scroll-bar();
  @include app-general($general-theme);
  @include app-forms($forms-theme);
  @include app-navigation($navigation-theme);
  @include app-sms($sms-theme);
  @include app-sms-template();
  @include app-legacy($legacy-theme);
  @include app-datatable((
    'primary': map-get($mat-primary, "main"),
    'accent': map-get($mat-accent, "main"),
    'warn': map-get($mat-warn, "main"),
    'dark-text': $dark-text,
    'light-text': $light-01,
    'header-background': $light-04,
    'border': $dark-dividers,
  ));
  @include app-reports((
    'primary': map-get($mat-primary, "main"),
    'accent': map-get($mat-accent, "main"),
    'warn': map-get($mat-warn, "main"),
    'dark-text': $dark-text,
    'light-text': $light-01,
    'header-background': $light-04,
    'border': $dark-dividers,
  ));


  .light-text {
    color: #636E8B;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #2e3d83;
  }
}
