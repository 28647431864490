/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'scss/kite/components/kendo_variables';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'scss/kite/theme.scss';
@import 'scss/material/theme';
@import 'scss/material/theme-dark';

:root {
  --new-blue: #2c5ea9;
}

#beacon-container {
  visibility: hidden;
}

button:focus {
  outline: none;
}

.zoom-icon {
  background-image: url('assets/icons/zoom-icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  // height: 20px;

  &.zoom-app-icon {
    height: 60px;
    display: inline-block;
    width: 60px;
  }

  &.zoom-home-icon {
    height: 70px;
    display: inline-block;
    width: 70px;
    margin: 10px;
  }

  &.zoom-menu-icon {
    height: 20px;
    display: inline-block;
    width: 20px;
    min-width: 20px !important;
    margin-right: 7px;
  }
}

.mat-mdc-button-base {
  text-transform: uppercase;

  .k-i-loading {
    display: none;
  }

  &.is-loading .k-i-loading {
    margin-left: 5px;
    font-size: $font-size-base;
    display: inline-block;
    padding: 0 0.1rem;
  }
}

.wrapper {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  height: calc(100vh - 65px);
}

.col-auto-main {
  -ms-flex: 1 auto;
  flex: 1 auto;
  width: auto;
  overflow: auto;
}

.btn-logout {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.btn-floating {
  width: 40px;
  height: 40px;
  line-height: 42px;
}

form {
  input[type='checkbox'] {
    + label {
    }

    &:checked {
      + label {
        &:before {
          background-color: $brand-blue-500;
          border-color: $brand-blue-500;
        }
      }
    }
  }

  .form-group {
    display: block;
  }
}

.k-popup {
  background-color: white;
}

.k-chart-tooltip-wrapper .k-animation-container {
  margin-left: 25px;
}

.user-popup-menu {
  .k-popup {
    color: $body-color;
    background-color: transparent;
    border-color: transparent;

    .popup-arrow {
      border-color: transparent;
      border-bottom-color: #fff;
      border-style: dashed dashed solid;
      border-width: 0 8.5px 8.5px;
      position: absolute;
      right: 11.5px;
      top: 3px;
      z-index: 1;
      height: 0;
      width: 0;
    }

    .card {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}

.k-content,
.k-window,
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper,
.k-editor,
.k-scheduler-agendaview tr.k-state-hover .k-scheduler-datecolumn,
.k-notification {
  color: $body-color;
}

.k-dialog {
  min-width: auto;
  max-width: 100%;
  max-height: 100%;

  .k-window-title {
    line-height: 1.5;
    font-size: 18px;
  }

  .k-dialog-titlebar {
    background-color: transparent;
    color: $body-color;
    border: none;
  }

  @include media-xs() {
    margin: 20px 0;
  }
}

.readonly {
  &.k-widget:disabled,
  &.k-block:disabled,
  &.k-widget[disabled],
  &[disabled].k-block {
    opacity: 1;
  }
}

.items-container {
  height: 255px;
  overflow: auto;
}

.top-item--title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ededed;
}

.top-item .top-item--percent {
  width: 50px;
  vertical-align: middle;
}

.top-item span {
  display: inline-block;
}

.top-item .top-progress {
  height: 10px;
  width: calc(100% - 130px);
  margin: 0 10px;
  vertical-align: middle;
  display: inline-block;
}

.top-item .top-item--text {
  width: 100px;
  vertical-align: middle;
}

.top-item span {
  display: inline-block;
}

.progress-bar {
  height: 100%;
}

.progress-outgoing {
  background-color: #00a3cc;
}

.progress-bar-incoming {
  background-color: $brand-success;
}

.progress-bar-info {
  background-color: #00a3cc;
}

.progress-bar-success {
  background-color: $brand-success;
}

.progress-bar-warning {
  background-color: $brand-warning;
}

.list-group-item {
  .badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    //color: #fff;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    //background-color: #777;
    border-radius: 10px;
  }

  > .badge {
    float: right;
  }
}

.search-thread {
  .form-group {
    margin-bottom: 0;
  }

  input {
    padding-left: 30px;
  }
}

html,
body {
  height: 100%;
}

/*body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}*/

//NOTE: open issue https://github.com/h2qutc/angular-material-components/issues/348 styles can be removed after fix
.mat-datepicker-content {
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de;
}

.mat-calendar-body-selected {
  background-color: var(--mat-datepicker-toggle-active-state-icon-color);
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}
