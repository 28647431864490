/**
 * Utilities
 */

/*** Square ***/

@mixin square($size){
	width: $size;
	height: $size;
	line-height: $size;
	text-align: center;
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

/*** Margin bottom ***/

.mb_sm {
	margin-bottom: 5px;
}
