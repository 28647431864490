// ===========
// List groups
// ===========

$list-group-border: $grey-light;
$list-group-hover-bg: $grey-lighter;

.list-group-item-heading {
	margin-bottom: 10px;
	font-size: 16px;
}